@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('/fonts/Inter-Bold.woff2') format('woff2'),
    url('/fonts/Inter-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('/fonts/Inter-Regular.woff2') format('woff2'),
    url('/fonts/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('/fonts/Inter-Medium.woff2') format('woff2'),
    url('/fonts/Inter-Medium.woff') format('woff');
}

:root {
  --white: rgba(255, 255, 255, 0.95);
  --dark-grey: #393939;
  --red: #dd2e44;
  --blue: #3b88c3;
  --bounce: cubic-bezier(0.13, 0.52, 0.6, 1.4);
  --app-height: 100vh;
}

html,
body {
  font-size: 11px;
  /* overflow-y: hidden; */
  position: relative;
  /* height: var(--app-height); */
}

body {
  /* background-color: #393939;
  color: var(--white); */
  font-weight: 600;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
}

/* main {
  position: relative;
  z-index: 1;
} */

h1 {
  font-size: 36px;
  letter-spacing: 0.02em;
}

/* h2 {
  border-bottom: 4px solid var(--white);
} */
/* 
.subtitle .marquee-text {
  font-size: 22px;
}

.subtitle .subtitle-box {
  border-left: 4px solid var(--white);
  padding: 1.5em 2em;
  display: inline-block;
} */

.line {
  height: 4px;
  background: #1a1a1a;
}

/* header {
  text-align: left;
  border-bottom: 4px solid var(--white);
  overflow: hidden;
}

.cta {
  text-align: center;
  padding: 12vw;
  border-bottom: 4px solid var(--white);
}

.cta .button {
  display: inline-block;
  margin: 2em auto;
} */

header a {
  text-decoration: none;
}

header .icon-button {
  top: 2px;
  position: absolute;
}

header .icon-button:first-child {
  left: 0;
}

header .icon-button:last-child {
  right: 0;
}

header h2 {
  font-size: 18px;
  text-align: center;
  width: 100%;
  padding-top: 18px;
}

header h3 {
  font-size: 28px;
  text-align: center;
  margin: 0.66em 0;
}

header .button.icon-button img {
  /* width: 32px; */
}

footer {
  left: 0;
  right: 0;
  text-align: left;
  font-size: 12px;
  padding: 3px 1em;
}

a,
a:visited,
a:active {
  color: #1a1a1a;
}

footer a,
header a {
  text-decoration: none;
}

.emoji {
  height: 1em;
  width: auto;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}

p {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.5em;
}

/* main {
  height: 100%;
  width: 100%;
  max-width: 768px;
  margin: auto;
} */

/* 768px + 8px border */
@media screen and (min-width: 776px) {
  main {
    /* border-left: 4px solid var(--white); */
    /* border-right: 4px solid var(--white); */
    /* box-shadow: rgba(0, 0, 0, 0.67) 0px 0px 40px 0px; */
  }

  footer {
    text-align: left;
  }
}

/* main.homepage {
  overflow-y: auto;
} */
